import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import tw from 'twin.macro'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { Container } from '../components/styles'
import { FormHeader } from '../components/FormControls'

const pageTitle = `Check your email`

const EmailConfirmationPage = ({ location }) => {
  const { state } = location

  return (
    <Layout hideSteps>
      <SEO title={pageTitle} />
      <Container>
        <FormHeader>
          <h1 tw="text-2xl md:text-3xl font-bold text-purple mb-4">{pageTitle}</h1>
          <p>
            An email with instructions on how to continue has been sent to
            {state?.data?.email ? ':' : ' your email address.'}{' '}
            <span tw="block text-lg text-purple font-bold my-4">{state?.data?.email}</span>
            It may take a few minutes to arrive in your inbox.
          </p>
          <p>Click on the button in the email in order to move ahead with the sign-up process.</p>
        </FormHeader>
      </Container>
    </Layout>
  )
}

EmailConfirmationPage.propTypes = {
  location: PropTypes.object,
}

EmailConfirmationPage.defaultProps = {}

export default EmailConfirmationPage
